import { validateGuid, validateURL } from "../utils/validators";
import { convertVersionStringToInt } from "./versionConverter";
import { getHandlerByVersionInt } from "../viewerHandlers";
import axios from "axios";

class WebViewerSDK {
    constructor(store) {
        this._store = store;
    }

    setServerURL(value) {
        this._store.serverConfig.serverBaseUrl = value;
        return this;
    }

    setAssetsUrl(value) {
        this._store.serverConfig.assetsBaseUrl = value;
        return this;
    }

    setLanguages(value) {
        this._store.languageConfig.languages = value;
        return this;
    }

    setLanguagesResources(value) {
        this._store.languageConfig.languagesResources = value;
        return this;
    }

    setDefaultLanguage(value) {
        this._store.languageConfig.defaultLanguage = value;
        return this;
    }

    setProjectGuid(value) {
        this._store.projectConfig.guid = value;
        return this;
    }

    getFloor(params) {
        if (params && Object.getOwnPropertyNames(params).includes("elemID")) {
            const selectedItemId = params['elemID'];
            return this._getElemIdFloor(this._store.projectConfig.id, selectedItemId)
                .then(floorId => {
                    return handlerInstance.floorHandler.setActiveFloorById(floorId.data.floor_id);
                })
        }
        else {
            return handlerInstance.floorHandler.setActiveFloorById(this._store.floors[0].id);
        }
    }

    _getElemIdFloor(projectID, selectedItemId) {
        return axios.get(
            `${serverURL}api/project-access/select/v1/search-floor-id/${projectID}/${selectedItemId}`,
        );
    }

    generateHandler(closure) {
        this._validateFields();

        return WebViewerSDK._getProjectInfo(this._store.projectConfig.guid)
            .then(result => {
                this._store.projectConfig.databaseVersion = result.data['project_version'];
                this._store.projectConfig.databaseVersionInt = WebViewerSDK._convertVersionString(
                    this._store.projectConfig.databaseVersion
                );
                this._changeViewer();

                this._store.projectConfig.project_name = result.data['project_name'];
                document.title = result.data['project_name'];
                this._store.projectConfig.id = result.data['project_id'];
                this._store.floors = result.data['floors'];

                this._store.userConfig.ownerEmail = result.data['owner_email'];
                this._store.userConfig.ownerId = result.data['owner_id'];
                this._store.readonly_shared_with = result.data['readonly_shared_with'];
                this._store.shared_with = result.data['shared_with'];
                return getHandlerByVersionInt(this._store.projectConfig.databaseVersionInt);
            })
            .then(res => {
                return new res.default(this._store);
            })
            .catch(error => {
                document.getElementById('root').style = 'display: flex; align-content: center; justify-content: center;'
                document.getElementById('root').innerHTML = '<div><h1>No database found for this project</h1></div>';
                console.error(error);
                throw new Error("Handler not found for this database version");
            })
    }

    static _getProjectInfo(projectGuid) {
        return axios.get(serverURL + "/api/project-access/select/v1/3d-data/" + projectGuid);
    }

    getParamsFromURL() {
        const afterHash = window.location.hash.substr(1);

        let paramsObject;

        if (!afterHash) {
            return;
        }
        paramsObject = JSON.parse(decodeURIComponent(afterHash));
        return paramsObject;
    }

    _capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    _changeViewer() {
        if (this._store.projectConfig.databaseVersionInt <= 3000003000) {
            const baseUrl = 'https://cloud.orthograph.net/3D/';
            const afterHash = window.location.hash;
            const link = `${baseUrl}?project_guid=${this._store.projectConfig.guid}${afterHash}`;
            window.location.href = link
        }
    }
    colorProject(params) {
        if (params && Object.getOwnPropertyNames(params).includes("paint")) {
            Object.keys(params.paint).forEach(paintedType => {
                params.paint[paintedType].forEach(element => {
                    //if color is not in  color: {r, g, b} format change to that
                    if (!(element.color instanceof Object)) {
                        element.color = this.hexStringToRGB(element.color);
                    }
                    handlerInstance.selectionHandler.addObjectColoring(element.color, element.id, this._capitalizeFirstLetter(paintedType));
                });
            });
        }

        if (params && Object.getOwnPropertyNames(params).includes("elemID")) {
            handlerInstance.cameraHandler.setActiveCameraByName('Orthographic Camera');
            handlerInstance.selectionHandler.selectItemById(params['elemID']);
        }
    }

    setCameraFromURL(url) {
        const camera = url.searchParams.get('camera');

        if(!camera){
            return;
        }

        switch (camera) {
            case 'orthographic':
                handlerInstance.cameraHandler.setActiveCameraByName('Orthographic Camera');
                break;
            default:
                break;
        }
    }

    hexStringToRGB(rgbStringWithHash) {
        const r = parseInt('0x' + rgbStringWithHash[1] + rgbStringWithHash[2], 16);
        const g = parseInt('0x' + rgbStringWithHash[3] + rgbStringWithHash[4], 16);
        const b = parseInt('0x' + rgbStringWithHash[5] + rgbStringWithHash[6], 16);

        return { r, g, b };
    }

    _validateFields() {
        WebViewerSDK._validateUrl(this._store.serverConfig.serverBaseUrl, 'serverBaseUrl');
        WebViewerSDK._validateUrl(this._store.serverConfig.assetsBaseUrl, 'assetsBaseUrl');
        WebViewerSDK._validateGuid(this._store.projectConfig.guid, 'projectGuid');
    }

    static _validateUrl(value, fieldName) {
        if (!validateURL(value)) {
            throw new Error(`Invalid field: ${fieldName} with value ${value}`)
        }
    }

    static _validateGuid(value, fieldName) {
        if (!validateGuid(value)) {
            throw new Error(`Invalid field: ${fieldName} with GUID ${value}`)
        }
    }

    static _convertVersionString(databaseVersion) {
        try {
            return convertVersionStringToInt(databaseVersion);
        }
        catch (e) {
            throw new Error("Invalid field: database version");
        }
    }


}

export default WebViewerSDK;